@import "../carousel";

.scoreSheet {
    background-color: white;
    display: grid;
    grid-template-columns: 1fr;
    grid-template-rows: 1fr 4em;
    height: 100%;
}

.embedded {
    background-color: white;



    .EmbeddedSwiper {
        background-color: white;
        --header-height: 0;
        --footer-height: 0;

        height: calc(var(--vph, 100vh));

        .Media {
            background-color: white;
        }

        @media (max-width: 600px) {
            --footer-height: 0;
        }
    }
}

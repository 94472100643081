
.container {
  height: calc(var(--vph, 100vh) - 10em);
  width: calc(100vw - 10em);
  padding: 1em;
  @media (max-width: 600px) {
    height: var(--vph, 100vh);
    width: 100vw;
  }
  background-color: #2d0101;
  border-radius: 16px;
}

.container img {
  height: 100%;
  width: 100%;
  object-fit: contain;
}

.lightbox {
  height: 100vh;
  width: 100vw;
  display: flex;
  align-items: center;
  justify-content: center;
}

.close {
  position: absolute;
  top: 52px;
  right: 52px;
  background-color: white;
  height: 32px;
  width: 32px;
  border-radius: 50%;
  box-shadow: black 0px -1px 16px 4px;
  display: flex;
  justify-content: center;
  align-items: center;
  z-index: 11;

  @media (max-width: 600px) {
    top: 1em;
    right: 1em;
    background-color: white;
    border-radius: unset;
    box-shadow: unset;
  }

  &:hover {
    background-color: gray;
  }
}

.logo {
  display: block;
  position: absolute;
  right: 40px;
  cursor: pointer;
  & > * {
    height: 40px;
    width: 172px;
    cursor: pointer;
  }
}

.logoIcon {
  @media (max-width: 600px) {
    display: none;
  }
}

.chipIcon {
  display: none;
  position: relative;
  left: calc(100% - 20px);
  max-width: 40px;
  @media (max-width: 600px) {
    display: block;
  }
}
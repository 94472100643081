.chip {
  height: 25px;
  width: 25px;
  display: inline-block;
  top: 6px;
  position: relative;
  padding-right: 10px;
}

.loader {
  position: absolute;
  height: 70px;
  width: 70px;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
}

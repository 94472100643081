.comingSoonView {
  text-align: center;
  display: flex;

  height: calc(var(--vph, 100vh) - 102px);
  flex-direction: column;
  width: 100%;

  .lonely {
    text-align: center;
  }

  .helpText {
    position: relative;
    top: 50%;
    left: 50%;
    transform: translate(-50%);

    animation: pulsate 3s ease-out;
    animation-iteration-count: infinite;
    opacity: 0;
  }
}

@keyframes pulsate {
  0% {
    opacity: 0;
  }
  50% {
    opacity: 1;
  }
  100% {
    opacity: 0;
  }
}

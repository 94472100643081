
.alert {
  display: flex;
  .icon {
    display: inline-block;
    vertical-align: middle;
  }

  .text {
    display: inline-block;
    vertical-align: middle;
    margin-left: 20px;
    margin-top: 8px;
  }

};

.prompt {

  .input {
    width: 400px;
    overflow: hidden;
    background-color: black;
    z-index: 1;
  };

  .text {
    display: block;
    width: 100%;
    margin-bottom: 12px;
  };

}
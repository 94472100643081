@import "../carousel";


.imageCatalogView {
  text-align: left;


  .lonely {
    text-align: center;
  }

  .helpText {
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%);
  }
}

.container {
  position: absolute;
  top: 0;
  left: 0;
  height: 100%;
  width: 100%;
}

.cardContent {
  padding: 8px;
}

.cardButtons {
  @media (min-width: 710px) {
    margin-left: 211px;
  }
}
.spinner {
  margin: auto;
}

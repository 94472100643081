@import "./global";

.Showcase,
.Leaderboard,
.Carousel,
.Catalog,
.Swiper {
    --swiper-navigation-color: #771818;
    --swiper-pagination-color: #771818;

    --header-height: 48px;
    --footer-height: 64px;

    @media (max-width: 600px) {
        --footer-height: 56px;
    }

    color: #494949;
    height: calc(var(--vph, 100vh) - var(--header-height) - var(--footer-height));

    .Banner {
        height: 100%;
        position: relative;

        .Media {
            background-color: black;
            height: 100%;
            overflow: hidden;
            background-size: contain;

            position: relative;

            .MediaCaption {
                text-overflow: ellipsis;

                position: absolute;
                bottom: 0;

                padding: 15px;

                background-color: black;
                color: white;
                opacity: 0.6;

                width: 100%;
                height: 10%;

                font: {
                    size: $bigger-font-size;
                    weight: 200;
                }

                transition: 300ms;
                cursor: pointer;
                &:hover {
                    opacity: 0.8;
                }
            }

            transition: 300ms;
            cursor: pointer;
            &:hover {
                filter: brightness(115%);
            }
        }

        .BannerGrid {
            height: 100%;
            position: relative;
            flex-direction: column;
        }

        .ContentGrid {
            max-width: unset;
            flex-basis: unset;
            max-height: 60px;
            min-height: 60px;
            flex-direction: column;
        }

        .MediaGrid {
            max-width: unset;
            flex-basis: unset;
            flex-grow: 1;
            flex-direction: column;
        }

        .Content {
            color: white;
            background-color: $dark-red;
            height: 100%;
            padding: 10px;
            position: relative;

            cursor: pointer;

            transition: 300ms;

            .Actions {
                padding: 0;
                margin-left: auto;
                margin-right: auto;
                justify-content: center;


                .Checkbox {
                    color: white;
                }
            }

            &:hover,
            &:active {
                background-color: $dark-red-active;

                .ViewButton {
                    background-color: $almost-white;
                    color: $dark-red;
                }
            }

            .Title {
                font-size: $big-header-font-size;
                font-weight: 500;
                color: white;
            }

            .Caption {
                margin-top: 10px;
                font-size: $normal-font-size;
                color: white;
            }

            .ViewButton {
                margin-top: 40px;
                color: white;

                font-size: $header-font-size;
                border: 3px solid white;
                text-transform: capitalize;

                transition: 200ms;
            }
        }
    }
}

.lonely {
    text-align: center;
    color: white;
}

.helpText {
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%);
}

div.loader {
    display: flex;
    height: 100%;
    width: 100%;
}

span.loader {
    margin: auto;
}

@import "../carousel";


.imageCatalogView {
  text-align: left;

  /* this belongs in carousel.scss but we just copy for now */
  --header-height: 48px;
  --footer-height: 64px;

  @media (max-width: 600px) {
      --footer-height: 56px;
  }

  height: calc(var(--vph, 100vh) - var(--header-height) - var(--footer-height));

  .lonely {
    text-align: center;
  }

  .helpText {
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%);
  }
}

.container {
  position: absolute;
  top: 0;
  left: 0;
  height: 100%;
  width: 100%;
}

.cardContent {
  padding: 8px;
}

.cardButtons {
  @media (min-width: 710px) {
    margin-left: 211px;
  }
}

.loader {
  position: absolute;
  top: calc(50% - 42px);
  left: calc(50% - 42px);
}

.full {
  background-image: url(./darkroomers.jpg);
  background-repeat: no-repeat;
  background-size: contain;
  width: 300px;
  height: 70px;
}

.dark {
  background-image: url(./dark.png);
  background-repeat: no-repeat;
  background-size: contain;
  width: 300px;
  height: 70px;
}

.chip {
  background-image: url(./chip.png);
  background-repeat: no-repeat;
  background-size: contain;
  width: 192px;
  height: 192px;
}

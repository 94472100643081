$footerHeight: 53px;
$headerHeight: 80px;

.workspace {
  &.standings {
    background-color: white;
  }
  text-align: center;
  min-height: var(--vph, 100vh);
  background-color: #282c34;
  font-size: calc(10px + 2vmin);
  color: white;
}


.text {
  input {
    color: white;
  }
}

.menuTitle {
  padding: 0 8px;
  display: block;
}

.categorySelector {
  position: relative;
  margin-bottom: 6px;
  max-width: 350px;

  svg {
    color: white;
  }
}

.viewSelector {
  position: absolute;
  right: 10px;

  background-color: white;
}

.actions {
  background-color: white;
  margin-right: 20px;
}

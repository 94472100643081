@import "../carousel";

.identificationBug {
    position: relative;
    background-color: #b570ff;
    border: 4px solid white;
    position: absolute;
    display: flex;
    flex-direction: column;
    text-align: center;
    color: black;
    border-radius: 0 0 25% 25%;
    font-size: 0.3em;
    top: 4px;
    right: 48px;
    z-index: 2;
    height: 54px;
    max-height: 54px;
    min-width: 148px;
    text-transform: capitalize;

    .smallText {
        display: none;
    }

    .pretext,
    .smallText {
        font-size: 0.3em;
        font-size: 0.4em;
        background-color: rgb(233, 202, 200);
        padding: 0.8em 2em;


        .logo {
            max-width: 24px;
            max-height: 24px;
            margin-top: auto;
            margin-bottom: auto;
        }
    }

    @media (max-width: 600px) {
        min-width: 4.2em;
        top: 0;
        right: 23px;

        .pretext {
            display: none;
        }
        .smallText {
            display: block;
        }
    }

}
